// libraries.
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TablePagination,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

// styles.
import {
  ContainerTable,
  CustomPaginatorContainer,
  StyledFirstDataCell,
} from '../TwoDimensionalTable/styles';

// utils.
import { getCellValue, getStatusColor, headers } from './utils';
import { useState } from 'react';
import { TablePaginationContainer } from '../InverterTable/styles';
import { useGetHistoricalSalesQuery } from '../../../../../../apis/historicalSales/historicalSales.endpoints';
import { useSelector } from 'react-redux';
import { ContainerComment } from '../DetailsRoomsBathrooms/styles';

export const DetailHistoricalSalesInfo = () => {
  const country = localStorage.getItem('country');
  const nid = useSelector((state: any) => state.searchStore.search);

  const { data } = useGetHistoricalSalesQuery({
    country: country ?? '',
    nid: nid,
  });
  const [page, setPage] = useState(0);
  const rowsPerPage = 4;

  const paginatedData = data?.table_data?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  if (data?.table_data?.length === 0) {
    return (
      <ContainerComment>
        <div className="title">Comentario: </div>
        <div className="subtitle" data-testid="description">
          No se encontraron datos
        </div>
      </ContainerComment>
    );
  }

  return (
    <ContainerTable>
      <Paper
        style={{
          borderRadius: '6px',
          boxShadow: 'none',
        }}
      >
        <TableContainer
          component={Paper}
          data-testid="inverter-table"
          sx={{
            borderRadius: '6px 6px 0 0',
            boxShadow: 'none',
          }}
        >
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {headers.map((header, rowIndex) => (
                <TableRow key={uuidv4()}>
                  <StyledFirstDataCell
                    sx={{ textAlign: 'left !important', width: '144px' }}
                  >
                    {header}
                  </StyledFirstDataCell>
                  {paginatedData?.map((item, colIndex) => (
                    <TableCell
                      key={`cell-${rowIndex}-${colIndex}`}
                      sx={{
                        textAlign: 'center',
                        fontSize: '12px',
                        padding: '8px',
                        border: '1px solid #ddd',
                        color:
                          header === 'Estatus'
                            ? getStatusColor(item.status)
                            : 'inherit',
                      }}
                    >
                      {getCellValue(item, header)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePaginationContainer>
          <CustomPaginatorContainer>
            <div className="results">{paginatedData?.length} resultados</div>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={data?.table_data?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handlePageChange}
              labelDisplayedRows={({ from, to, count }) => {
                const total = count !== -1 ? count : `más de ${to}`;
                return `${from}-${to} de ${total}`;
              }}
            />
          </CustomPaginatorContainer>
        </TablePaginationContainer>
      </Paper>
    </ContainerTable>
  );
};
