// libraries.
import { useDispatch, useSelector } from 'react-redux';

// api.
import { getPropertyTypologies } from '../../../apis/approvalRules/approvalRules';

// slices.
import { setResult } from '../../../redux/reducers/searchSlice';
import { updateCardState } from '../../../redux/reducers/approverCardsSlice';

export const handleInfoApproved = (value: string) => {
  if (value === 'approved') return 'positive';
  if (value !== 'rejected') return 'warning';
  return 'negative';
};

export const getSafeValue = (value?: number) => value ?? '-';

export const useGetTypologies = () => {
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.searchStore.search);

  const handleGetBedroomBathroomMX = async () => {
    try {
      const response = await getPropertyTypologies({ nid: search });
      const { bed_room_typology } = response.data;

      const updatedData = {
        id: 3,
        size: 's',
        edited: false,
        approved: handleInfoApproved(bed_room_typology.approval_result),
        loading: false,
        showDetail: true,
        data: {
          title: 'Tipología Habitaciones y Baños',
          values: {
            text_one: 'Habitaciones',
            value_one: getSafeValue(bed_room_typology.rooms),
            text_two: 'Baños',
            value_two: getSafeValue(bed_room_typology.baths),
          },
        },
        table: bed_room_typology,
      };

      dispatch(updateCardState({ id: 3, newData: updatedData }));
      const status = bed_room_typology.approval_result ? 'ready' : 'error';
      dispatch(setResult(status));
    } catch (error) {
      console.error('Error en handleGetBedroomBathroom:', error);
    }

    return true;
  };

  const handleGetElevatorMX = async () => {
    try {
      const response = await getPropertyTypologies({ nid: search });
      const { elevator_typology } = response.data;

      const updatedData = {
        id: 10,
        size: 's',
        approved: handleInfoApproved(elevator_typology.approval_result),
        edited: false,
        loading: false,
        showDetail: true,
        data: {
          title: 'Tipologías de Elevadores',
          values: {
            text_one: 'Elevadores',
            value_one: getSafeValue(elevator_typology.elevator),
          },
          dataTable: '',
          elevator: elevator_typology.elevator,
          nid: search,
          approval_result: elevator_typology.approval_result,
          median_zone: '',
        },
      };
      dispatch(updateCardState({ id: 10, newData: updatedData }));
      dispatch(setResult('ready'));
    } catch (error) {
      console.error(error);
    }

    return true;
  };

  const handleGetGarageTypologyMX = async () => {
    try {
      const response = await getPropertyTypologies({ nid: search });
      const { garage_typology } = response.data;
      const updatedData = {
        id: 7,
        size: 's',
        edited: false,
        approved: handleInfoApproved(garage_typology.approval_result),
        loading: false,
        showDetail: false,
        data: {
          title: 'Tipologías de Estacionamiento',
          values: {
            text_one: 'Estacionamientos',
            value_one: garage_typology.garage,
          },
        },
        table: response,
      };

      dispatch(updateCardState({ id: 7, newData: updatedData }));
      const status = garage_typology.approval_result ? 'ready' : 'error';
      dispatch(setResult(status));
    } catch (error) {
      console.error('error');
    }

    return true;
  };

  const handleGetAreaTypologyMX = async () => {
    try {
      const response = await getPropertyTypologies({ nid: search });
      const { area_typology } = response.data;
      const updatedData = {
        id: 22,
        size: 's',
        edited: false,
        approved: handleInfoApproved(area_typology.approval_result),
        loading: false,
        showDetail: false,
        data: {
          title: 'Tipología de Área',
          values: {
            text_one: 'Área',
            value_one: area_typology.area,
          },
        },
        table: response,
      };

      dispatch(updateCardState({ id: 22, newData: updatedData }));
      const status = area_typology.approval_result ? 'ready' : 'error';
      dispatch(setResult(status));
    } catch (error) {
      console.error('error');
    }

    return true;
  };

  return {
    getSafeValue,
    handleInfoApproved,
    handleGetElevatorMX,
    handleGetAreaTypologyMX,
    handleGetGarageTypologyMX,
    handleGetBedroomBathroomMX,
  };
};
