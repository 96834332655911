// libraries.
import GoogleMap from '../../../../../../domains/google-map/GoogleMap';
import { Circle } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

// hooks.
import { useAppDispatch } from '../../../../../../redux/hooks';
import { updateShowDetail } from '../../../../../../redux/reducers/searchSlice';
import { useGetHistoricalSalesQuery } from '../../../../../../apis/historicalSales/historicalSales.endpoints';

// styles.
import {
  ContainerValidationRoomsBathrooms,
  StyledButton,
  TitleNameCounterValidations,
} from '../ValidationRoomsBathrooms/styles';

// assets.
import ControlUp from '../../../../../../assets/images/ControlUp.svg';
import ControlDown from '../../../../../../assets/images/ControlDown.svg';
import { DetailHistoricalSalesInfo } from '../DetailHistoricalSalesInfo/DetailHistoricalSalesInfo';
import { getStatusColor } from '../DetailHistoricalSalesInfo/utils';

export const ValidationHistoricalSales = () => {
  const dispatch = useAppDispatch();
  const showDetail = useSelector((state: any) => state.searchStore.showDetail);
  const imgControl = showDetail ? ControlUp : ControlDown;

  const country = localStorage.getItem('country');
  const nid = useSelector((state: any) => state.searchStore.search);

  const { data } = useGetHistoricalSalesQuery({
    country: country ?? '',
    nid: nid,
  });
  const mapData = data?.map_data;
  const mainData = data?.main;

  const center = {
    lat: Number(mainData?.coordinates?.latitude) ?? 0,
    lng: Number(mainData?.coordinates?.longitude) ?? 0,
  };

  const handleChangeShowDetail = () => {
    dispatch(
      updateShowDetail({
        itemName: 'historicalSales',
        itemValue: !showDetail.historicalSales,
      })
    );
  };

  return (
    <ContainerValidationRoomsBathrooms>
      <TitleNameCounterValidations>
        <div className="icon-title">
          <StyledButton
            onClick={handleChangeShowDetail}
            data-testid="show-data"
          >
            <img src={imgControl} alt="ampliar replegar" />
          </StyledButton>
          <div className="title">Ventas históricas</div>
        </div>
      </TitleNameCounterValidations>

      {showDetail.historicalSales && (
        <>
          <div style={{ paddingBottom: '15px' }}>
            <GoogleMap
              containerStyle={{ height: '300px', width: '100%' }}
              center={center}
            >
              {mapData?.map((item: any) => (
                <Circle
                  key={item.nid}
                  center={{
                    lat: Number(item.coordinates.latitude),
                    lng: Number(item.coordinates.longitude),
                  }}
                  radius={50}
                  options={{
                    fillColor: getStatusColor(item.status),
                    fillOpacity: 1,
                    strokeColor: 'white',
                    strokeOpacity: 0.2,
                    strokeWeight: 8,
                    zIndex: 1,
                  }}
                />
              ))}
            </GoogleMap>
          </div>
          <DetailHistoricalSalesInfo />
        </>
      )}
    </ContainerValidationRoomsBathrooms>
  );
};
