import ElevatorTypology from './components/ElevatorTypology/ElevatorTypology';
import GarageTypology from './components/GarageTypology/GarageTypology';
import ValidationGoodZone from './components/ValidationGoodZone/ValidationGoodZone';
import ValidationHistoricalBusinessInformation from './components/ValidationHistoricalBusinessInformation/ValidationHistoricalBusinessInformation';
import { ValidationHistoricalSales } from './components/ValidationHistoricalSales/ValidationHistoricalSales';
import ValidationRoomsBathrooms from './components/ValidationRoomsBathrooms/ValidationRoomsBathrooms';
const country = localStorage.getItem('country');

const ValidationsTab = () => {
  return (
    <div>
      <ValidationGoodZone />
      {country === 'CO' && <ValidationRoomsBathrooms />}
      <ValidationHistoricalSales />
      <ValidationHistoricalBusinessInformation />
      {country === 'CO' && <ElevatorTypology />}
      {country === 'CO' && <GarageTypology />}
    </div>
  );
};

export default ValidationsTab;
