// libraries.
import { ModelError } from '../../interfaces/interfaces';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

// slices.
import { apiApproverPortal } from '../../redux/apiSlices/apiSlices';

// interfaces.
import { HistoricalSalesParams, ResponseBody } from './interfaces/interfaces';

export const historicalSales = apiApproverPortal.injectEndpoints({
  endpoints: (builder) => ({
    getHistoricalSales: builder.query({
      query: ({ country, nid }: HistoricalSalesParams) => {
        return `/historical_business_analytics?country=${country}&nid=${nid}`;
      },
      transformErrorResponse: (error: FetchBaseQueryError) => {
        const modelError: ModelError = {
          errorStatus: error.status,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          messageFromServer: (error.data as any)?.message,
          serviceErrorName: 'getHistoricalSales',
        };
        return modelError;
      },
      transformResponse: (response: ResponseBody) => {
        return response;
      },
    }),
  }),
});

export const { useGetHistoricalSalesQuery } = historicalSales;
