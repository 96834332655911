import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiApproverPortal = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.ENV_APPROVER_API_URL,
    prepareHeaders: headers => {
      headers.set('x-api-key', process.env.ENV_APPROVER_API_KEY || '');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: () => ({}),
  reducerPath: 'apiApproverPortal',
});
