/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@mui/material';
import { Button, DropdownButton } from '@habitech/call-to-action';
import { SearchInput } from '@habitech/search-input';
import {
  ContainerApprover,
  DialogButtons,
  DialogContainer,
  DialogDescription,
  DialogInput,
  DialogTitleContainer,
} from './styles';
// eslint-disable-next-line import/no-cycle
import GeneralSummary from './components/GeneralSummary/GeneralSummary';
import SearchProperty from './components/SearchProperty/SearchProperty';
import TabLine from './components/TabLine/TabLine';
// eslint-disable-next-line import/no-cycle
import RulesPendingApproved from './components/RulesPendingApproved/RulesPendingApproved';
import ValidationsTab from './components/ValidationsTab/ValidationsTab';
import ImagesResult from './components/ImagesResult/ImagesResult';
import {
  getRejectedReasons,
  putApproval,
} from '../../apis/approvalRules/approvalRules';
import {
  AlertStoreProps,
  alertStoreActions,
} from '../../redux/reducers/alertReducer';
import { updateCardState } from '../../redux/reducers/approverCardsSlice';
import AlertToast from '../Pricing/components/AlertToast/AlertToast';
import { useAppSelector } from '../../redux/hooks';
import { updateEditable } from '../../redux/reducers/searchSlice';

export const CardContext = createContext({});

interface IDialog {
  title: string;
  subtitle: string;
  description: string;
  options: any[];
  inputPlaceholder: string;
  okButton: (value: string) => void;
  cancelButton: () => void;
  handleInput: (value: string) => void;
  showInput: boolean;
  type: string;
  labelOkButton: string;
  isFinal: boolean;
}

const Approver = () => {
  const [inputVal, setInputVal] = useState<string>();
  const [modalDisableButton, setModalDisableButton] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const stateCards = useSelector((state: any) => state.cardsStore.stateCards);
  const historicalDeals = useSelector(
    (state: any) => state.cardsStore.historicalDeals
  );
  const isRules = useSelector((state: any) => state.searchStore.isRules);
  const alertState = useAppSelector(state => state.alertStore);
  const { hideAlert } = alertStoreActions;
  const emptyDialog: IDialog = {
    title: '',
    subtitle: '',
    description: '',
    options: [],
    inputPlaceholder: '',
    okButton: () => {},
    cancelButton: () => {},
    handleInput: value => {},
    showInput: false,
    type: '',
    labelOkButton: '',
    isFinal: false,
  };
  const approved = 'approved';
  const rejected = 'rejected';
  const searchState = useSelector((state: any) => state.searchStore);
  const { result, search, approval, trustLevel } = searchState;
  const user = useSelector((state: any) => state.userStore.user);
  const [pendingRules, setPendingRules] = useState<any[]>([]);
  const [approvedRules, setApprovedRules] = useState<any[]>([]);
  const [dialogConfirmation, setDialogConfirmation] = useState(false);
  const [rejectedReasons, setRejectedReasons] = useState<any[]>([]);
  const [dialogInfo, setDialogInfo] = useState(emptyDialog);
  const dispatch = useDispatch();
  const country = localStorage.getItem('country');
  const [comments, setComments] = useState('');
  const [disableButtonApprove, setDisableButtonApprove] = useState(true);
  const [selectedReason, setSelectedReason] = useState('');
  const [changeDecision, setChangeDecision] = useState(false);
  const editCardOptions = [
    { label: 'Rechazado', value: 'false' },
    { label: 'Aprobado', value: 'true' },
  ];
  const [cardApproved, setCardApproved] = useState('');
  const cardKeys: any = {
    13: 'high_level_report',
    5: 'photos',
    14: 'remodeling_comment',
    15: 'administration',
    6: 'good_zone',
    3: 'bathroom_room_typology',
    7: 'garage_typology',
    16: 'blacklist',
    8: 'pricing_according_zone',
    12: 'floor_elevator',
    20: 'estructural_damage',
    21: 'infonavit_fovissste_normative',
    1: 'final_price_alert',
    2: 'revenue_low',
    9: 'alert_final_price',
    18: 'committee_hubspot_price_alert',
    19: 'property_view',
    10: 'elevator_typology',
    11: 'punishment_antiquity_commite_automatic',
    17: 'risk_threats',
    22: 'area',
  };
  const COL_CARDS: number[] = [
    1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ];
  const MX_CARDS: number[] = [2, 3, 5, 6, 7, 8, 10, 12, 13, 14, 16, 20, 21, 22];
  const cardsByCountry = country === 'CO' ? COL_CARDS : MX_CARDS;
  const cardsToShow = stateCards.filter((item: any) =>
    cardsByCountry.includes(item.id)
  );
  const approvedCars = cardsToShow.filter(
    (item: any) => item.approved === 'positive'
  );
  const negativeAndWarningCars = cardsToShow.filter(
    (item: any) => item.approved !== 'positive'
  );

  const handleSaveCard = (id: number, status: boolean, data: any) => {
    const key = cardKeys[id];
    dispatch(updateEditable(false));
    setModalDisableButton(true);

    if (key) {
      const body = {
        country,
        params: {
          id: approval.approval_history_id,
          nid: Number(search),
          approval_user: user.email,
          [key]: !status ? 0 : 1,
        },
      };
      putApproval(body).then(response => {
        if (response.success) {
          const alertData: AlertStoreProps = {
            type: 'success',
            text: `Se han cambiado el resultado de la regla ${data.title}`,
            showAlert: true,
          };
          dispatch(alertStoreActions.updateAlert(alertData));
          const updatedData = {
            approved: !status ? 'negative' : 'positive',
            edited: true,
          };
          dispatch(updateCardState({ id, newData: updatedData }));
          setCardApproved('');
          setDialogConfirmation(false);
        }
      });
    } else {
      setCardApproved('');
      const alertData: AlertStoreProps = {
        type: 'error',
        text: `No se ha podido guardar el resultado de la regla ${data.title}`,
        showAlert: true,
      };
      dispatch(alertStoreActions.updateAlert(alertData));
      setDialogConfirmation(false);
    }

    setTimeout(() => {
      dispatch(updateEditable(true));
    }, 4000);
  };

  const handleEditCard = (id: number, data: any) => {
    setDialogInfo({
      title: data.title,
      subtitle: 'CAMBIAR RESULTADO',
      description:
        'Los cambios que se realicen sobre los resultados serán guardados al momento de realizar la aprobación o rechazo final del inmueble.',
      options: editCardOptions,
      inputPlaceholder: 'Resultado',
      okButton: (value: string) => {
        handleSaveCard(id, value === '1', data);
      },
      cancelButton: () => {
        setDialogConfirmation(false);
        setCardApproved('');
      },
      handleInput: (value: string) => {
        if (value === 'true') {
          setCardApproved('1');
        } else {
          setCardApproved('0');
        }
      },
      showInput: true,
      type: 'card',
      labelOkButton: 'Guardar cambios',
      isFinal: false,
    });
    setDialogConfirmation(true);
  };

  const handleConfirmApprove = (reason: string, type: string) => {
    const labelReason = rejectedReasons.find(item => item.value === reason);
    setModalDisableButton(true);
    let negociosHistoricos: any = 0;

    if (historicalDeals[0]) {
      negociosHistoricos = historicalDeals[0].negocios_historico;
    } else {
      negociosHistoricos = historicalDeals.negocios_historico;
    }

    if (negociosHistoricos === '-') {
      negociosHistoricos = 0;
    }

    const body = {
      country,
      params: {
        id: approval.approval_history_id,
        nid: Number(search),
        approval_user: user.email,
        reject_reason: labelReason?.label || '',
        reject_reason_hb: reason,
        comments,
        approval_decision: type,
        pricing_trust: trustLevel === '-' ? 0 : Number(trustLevel),
        historical_deals: negociosHistoricos,
      },
    };
    putApproval(body).then(response => {
      if (response.success) {
        const alertData: AlertStoreProps = {
          type: 'success',
          text: `Se ha confirmado ${
            type === approved ? 'la aprobación' : 'el rechazo'
          } de este inmueble`,
          showAlert: true,
        };
        dispatch(alertStoreActions.updateAlert(alertData));
        setChangeDecision(true);
        setDialogConfirmation(false);
      }
    });
  };

  const handleShowDialog = (status: boolean, type: string) => {
    setDialogConfirmation(status);

    switch (type) {
      case rejected:
        setDialogInfo({
          title: 'Rechazar inmueble',
          subtitle: 'CONFIRMACIÓN FINAL',
          description: '',
          options: rejectedReasons,
          inputPlaceholder: 'Seleccione un motivo de rechazo',
          okButton: (value: string) => {
            handleConfirmApprove(value, type);
          },
          cancelButton: () => {
            setDialogConfirmation(false);
            setSelectedReason('');
          },
          handleInput: (value: string) => {
            setSelectedReason(value);
          },
          showInput: true,
          type,
          labelOkButton: 'Rechazar inmueble',
          isFinal: true,
        });
        break;
      case approved:
        setDialogInfo({
          title: 'Aprobar inmueble',
          subtitle: 'CONFIRMACIÓN FINAL',
          description:
            '¿Está seguro que desea enviar aprobación final de este inmueble?',
          options: rejectedReasons,
          inputPlaceholder: 'Resultado',
          okButton: () => {
            handleConfirmApprove('', type);
          },
          cancelButton: () => {
            setDialogConfirmation(false);
          },
          handleInput: () => {},
          showInput: false,
          type,
          labelOkButton: 'Aprobar inmueble',
          isFinal: true,
        });
        break;
      default:
        setDialogInfo(emptyDialog);
        break;
    }
  };

  const handleAlert = () => {
    dispatch(hideAlert());
  };

  useEffect(() => {
    if (dialogInfo.type === approved) {
      setModalDisableButton(false);
    }

    if (dialogInfo.type === rejected) {
      setModalDisableButton(selectedReason === '');
    }

    if (dialogInfo.type === 'card') {
      setModalDisableButton(cardApproved === '');
    }
  }, [dialogInfo, cardApproved, selectedReason]);

  useEffect(() => {
    setPendingRules(approvedCars);
    setApprovedRules(negativeAndWarningCars);
    const rulesApproved = cardsToShow.filter(
      (item: any) => item.approved === 'positive'
    );
    const rulesLoading = cardsToShow.filter((item: any) => item.loading);

    if (
      cardsToShow.length === rulesApproved.length + rulesLoading.length &&
      comments !== ''
    ) {
      setDisableButtonApprove(false);
    } else {
      setDisableButtonApprove(true);
    }
  }, [stateCards, comments]);

  useEffect(() => {
    setChangeDecision(false);
    setComments('');
    setDialogInfo(emptyDialog);
  }, [stateCards]);

  useEffect(() => {
    getRejectedReasons().then(response => {
      if (response.success) {
        setRejectedReasons(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (inputVal) {
      const filtered = rejectedReasons.filter(item =>
        item.label.toLowerCase().includes(inputVal.toLowerCase())
      );
      setFilteredData(filtered as never[]);
    } else {
      setFilteredData(rejectedReasons as never[]);
    }
  }, [inputVal]);

  return (
    <ContainerApprover data-testid="approver">
      <SearchProperty />
      {result === 'ready' ? (
        <CardContext.Provider
          value={{
            handleEditCard,
            handleShowDialog,
            setComments,
            comments,
            disableButtonApprove,
            setDisableButtonApprove,
            changeDecision,
            setChangeDecision,
            approval,
          }}
        >
          <GeneralSummary />
          <TabLine />
          <div className="container-pending-approved">
            {isRules ? (
              <>
                <RulesPendingApproved
                  title="Pendiente de revisión"
                  numberRules={approvedRules?.length.toString()}
                  data={approvedRules}
                />
                <RulesPendingApproved
                  title="Aprobadas"
                  numberRules={pendingRules?.length.toString()}
                  data={pendingRules}
                />
              </>
            ) : (
              <ValidationsTab />
            )}
          </div>
        </CardContext.Provider>
      ) : (
        <ImagesResult result={result} />
      )}
      <Dialog
        open={dialogConfirmation}
        data-testid="dialog-card-approve"
        PaperProps={{ sx: { borderRadius: '6px' } }}
      >
        <DialogContainer>
          <DialogTitleContainer>
            <div className="subtitle">{dialogInfo.subtitle}</div>
            <div className="title">{dialogInfo.title}</div>
          </DialogTitleContainer>
          {dialogInfo.showInput && !dialogInfo.isFinal ? (
            <DialogInput>
              <DropdownButton
                dataId="test_id"
                label={dialogInfo.inputPlaceholder}
                listOptions={dialogInfo.options}
                placeholder={dialogInfo.inputPlaceholder}
                setValue={value => {
                  dialogInfo.handleInput(String(value));
                }}
                variant="white"
                size="fluid"
              />
            </DialogInput>
          ) : (
            dialogInfo.type === 'rejected' && (
              <DialogInput>
                <SearchInput
                  listOptions={filteredData}
                  dataId="autocomplete"
                  name="test"
                  onChange={(name, val) => {
                    setInputVal(val);
                  }}
                  onSelectItem={item => {
                    dialogInfo.handleInput(String(item.value));
                  }}
                />
              </DialogInput>
            )
          )}
          {dialogInfo.description !== '' && (
            <DialogDescription>{dialogInfo.description}</DialogDescription>
          )}
          <DialogButtons>
            <Button
              disabled={false}
              dataId="cancel"
              variant="stroke"
              size="large"
              onClick={() => {
                dialogInfo.cancelButton();
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={modalDisableButton}
              dataId="ok"
              variant="primary"
              size="large"
              onClick={() => {
                const value =
                  dialogInfo.type === approved || dialogInfo.type === 'card'
                    ? cardApproved
                    : selectedReason;
                dialogInfo.okButton(value);
              }}
            >
              {dialogInfo.labelOkButton}
            </Button>
          </DialogButtons>
        </DialogContainer>
      </Dialog>
      <AlertToast
        type={alertState.type}
        text={alertState.text}
        showAlert={alertState.showAlert}
        setShowAlert={handleAlert}
        top="75px"
      />
    </ContainerApprover>
  );
};

export default Approver;
