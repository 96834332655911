import { TableDataItem } from '../../../../../../apis/historicalSales/interfaces/interfaces';
import { formatToCurrency } from '../DetailHistoricalBusinnesInformation/utils';

export const headers = [
  'NID',
  '¿Habi es Habi?',
  'Peso',
  'Días en inventario',
  'Estatus',
  'Puntaje',
  'Precio por m²',
  'Categoría',
  'Tipo de venta',
];

export const getCellValue = (item: TableDataItem, header: string) => {
  switch (header) {
    case 'NID':
      return item.nid || 'N/A';
    case '¿Habi es Habi?':
      return item.is_habi ? 'Sí' : 'No';
    case 'Peso':
      return item.weight || 'N/A';
    case 'Días en inventario':
      return item.days_in_inventory;
    case 'Estatus':
      return item.status || 'N/A';
    case 'Puntaje':
      return item.score || 'N/A';
    case 'Precio por m²':
      return formatToCurrency(Number(item.price_per_m2)) || 'N/A';
    case 'Categoría':
      return item.category || 'N/A';
    case 'Tipo de venta':
      return item.sale_type || 'N/A';
    default:
      return 'N/A';
  }
};

export const getStatusColor = (status: string) => {
  const statusColors = {
    Disponible: '#F09700',
    Vendido: '#009175',
    Publicado: '#3483FA',
    'No publicado': '#979499',
    default: 'black',
  };

  if (status in statusColors) {
    return statusColors[status as keyof typeof statusColors];
  }
  return statusColors['default'];
};
