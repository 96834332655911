// libraries.
import { useDispatch, useSelector } from 'react-redux';

// api.
import { getRevenueCategory } from '../../../apis/approvalRules/approvalRules';

// slices.
import { setRevenueData } from '../../../redux/reducers/revenueSlice';
import { updateCardState } from '../../../redux/reducers/approverCardsSlice';
import { getSafeValue, handleInfoApproved } from './useTypologies';

export const useGetRevenueMX = () => {
  const dispatch = useDispatch();
  const search = useSelector((state: any) => state.searchStore.search);

  const defaultData = {
    id: 2,
    size: 'm',
    edited: false,
    approved: 'warning',
    loading: false,
    data: {
      title: 'Revenue demasiado bajo',
      values: [
        { title: 'Categoría', value: '-' },
        { title: 'Expectativa días venta', value: '-' },
        { title: '¿Habi es Habi?', value: '-' },
      ],
    },
  };

  const buildCardData = (result: any) => ({
    ...defaultData,
    approved: handleInfoApproved(result.approval_result),
    data: {
      ...defaultData.data,
      values: [
        { title: 'Categoría', value: getSafeValue(result.Categoria) },
        {
          title: 'Expectativa días venta',
          value: `${Number(result.DiasProb).toFixed(0)} Días`,
        },
        { title: '¿Habi es Habi?', value: result.HesH ? 'Sí' : 'No' },
      ],
    },
  });

  const processRevenueData = (data: any) => {
    if (!data) {
      return defaultData;
    }

    const probDays = Math.floor(Number(data.DiasProb));
    const revenueCategory = data.Categoria;

    dispatch(
      setRevenueData({
        probDays,
        revenueCategory,
      })
    );
    return buildCardData(data);
  };

  const handleGetRevenueMX = async () => {
    try {
      const apiData = await getRevenueCategory({ nid: search });
      const updatedData = processRevenueData(apiData);

      dispatch(updateCardState({ id: 2, newData: updatedData }));
      return true;
    } catch (error) {
      console.error('Error en handleGetRevenueMX:', error);
      dispatch(updateCardState({ id: 2, newData: defaultData }));
      return false;
    }
  };

  return {
    handleGetRevenueMX,
  };
};
