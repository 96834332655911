import { LayoutWrapper, ContainerPage } from './style';
import LayoutHeaderClean from '../LayoutClean';
import { ITemplateClean } from './TemplateCleanMx.utils';
import LayoutSidebarMx from '../LayoutSidebarMx';

const TemplateClean = ({ children }: ITemplateClean) => {
  return (
    <LayoutWrapper>
      <LayoutSidebarMx />
      <ContainerPage>
        <>
          <LayoutHeaderClean />
          {children}
        </>
      </ContainerPage>
    </LayoutWrapper>
  );
};

export default TemplateClean;
